<script setup lang="ts">
import { Btn } from 'webcc-ui-components'
import { TrackProfileEventType } from '~/constants/enums'

const { $tracking, $router, $ROUTE } = useNuxtApp()

const openIbanEditPage = () => {
  $tracking.trackEditProfile(TrackProfileEventType.IBAN)
  $router.push($ROUTE.PROFILE_PAYMENT_INFO)
}
</script>

<template>
  <BasicInfoCard
    v-if="$unleash.features['payments-change-iban']"
    variant="error"
  >
    {{ $t('components.common.ibanWarnMsg') }}
    <template #actions>
      <Btn
        :label="$t('components.common.ibanWarnBtn')"
        @click="openIbanEditPage"
      />
    </template>
  </BasicInfoCard>
</template>
